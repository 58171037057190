<template>
<div class="Z5ViewBGView">
  <ul v-if="viewStyle=='list'" class="infinite-list-wrapper viewList" v-infinite-scroll="updateMsgList" style="overflow:scroll">
    <li v-for="(item, index) in dataList"
        class="infinite-list-item viewLi"
        :infinite-scroll-disabled="disabled"
        @click="clickItem(item)">
      <z5-cell :z5-info="item" :order="'#' + (index + 1)"></z5-cell>
    </li>
  </ul>
  <div v-if="viewStyle=='rssi'" class="rssiView">
    <device-charts  :data-keys="['timestamp', 'rssi']" :data-list="dataList" :data-line-name="'rssi'"></device-charts>
  </div>
</div>
</template>

<script>
import Z5Cell from '@/views/deviceMQ/Z5View/z5Cell'
import MQmixin from '@/views/deviceMQ/MQmixin/MQmixin'
import DeviceCharts from '@/views/deviceMQ/deviceCharts/deviceCharts'

export default {
  name: 'Z5View',
  components: { DeviceCharts, Z5Cell },
  mixins: [MQmixin],
  data() {
    return {
      viewType:'Z5',
      loadingDivId: 'z5Loading',
      urlBlock: (config) => {
        for (const key in this.query) {
          let value = this.query[key]
          if (typeof config[key] == 'undefined' || key == 'time') {
            config[key] = value
          } else {
            config[key] = config[key] == '' ? value : config[key] + ',' + value
          }
        }
        return this.$api.deviceMQ.getZ5MsgList(config)
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.Z5ViewBGView {
  width: 100%;
  height: 100%;

  .viewList {
    width: calc(100%);
    max-height: calc(100%);
    list-style: none;
    padding: 0;

    .viewLi {
      width: calc(100%);
      margin-bottom: 5px;
    }
  }

  .rssiView {
    width: 100%;
    height: calc(100% - 50px);
    margin-bottom: 10px;
  }


}

</style>
